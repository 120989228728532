const Utility = {
  showFormError: function (form_selector, model_name, errors) {
    var error_label = $(form_selector + ' .error');
    if (error_label.length) {
      error_label.remove();
    }
    $('form').find('input.is-invalid').removeClass('is-invalid');
    var field_names = Object.keys(errors);
    $.each(field_names, function (key, field) {
      var error_msg = errors[field][0];
      var selector = $(form_selector + ' #' + model_name + '_' + field.replace('.', '-'));
      console.log(selector);
      selector.addClass('is-invalid');
      if (selector.find('div').length) {
        selector = selector.find('div').first();
      }
      selector.parent('div').append('<label class="error">' + error_msg + '</label>');
    });
  },

  initToast: function (alert_type, title, message){
    let htmlObject = document.getElementById('dynamic_toasts');
    htmlObject.innerHTML = this.generateHtml(alert_type, title, message);
    this.showToast();
  },

  generateHtml: function (alert_type, title, message){
    let html = `
      <div class="bs-toast toast toast-placement-ex m-2 fade top-0 end-0 hide bg-`+alert_type+`" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <i class="bx bx-bell me-2"></i>
          <div class="me-auto fw-semibold">`+title+`</div>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">`+ message +`</div>
      </div>
    `
    return html
  },

  showToast: function (){
    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var toastList = toastElList.map(function (toastEl) {
      return new bootstrap.Toast(toastEl, {})
    });
    toastList.map(function (toast){
      toast.show();
    });
  },

  toastSuccess: function (message) {
    if (message) {
      this.initToast('success', 'Success', message);
    }
  },
  toastWarning: function (message) {
    if (message) {
      this.initToast('warning', 'Warning', message);
    }
  },
  toastError: function (message) {
    if (message) {
      this.initToast('danger', 'Error', message);
    }
  }
}

export { Utility }
